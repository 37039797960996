import React from 'react';
// components
import MetaTagsProvider from '../../components/MetaTagsProvider';
import HeroSection from '../../sections/Product/components/common/HeroSection';
import ProductFaq from '../../sections/Product/components/common/ProductFaq';
import BuildTrust from '../../sections/Product/components/common/BuildTrust';
import Coverage from '../../sections/Product/TCM/Coverage';
import HelpInfo from '../../sections/Product/TCM/HelpInfo';
import Schedule from '../../sections/Product/components/common/Schedule';
import { tcmInfoCards, tcmProductPageFaq } from '../../helper/constant';
import SecondaryInfoSection from '../../sections/Product/components/common/SecondaryInfoSection';
import { tcmBulletPoints } from '../../sections/Product/components/utilities';
//assets
import TCM_demo from '../../images/product-assets/TCM-product-page-main-image-v2.gif';
import Monitor_Users from '../../images/product-assets/monitor_users.svg';
// layout
import RootLayout from '../../layouts/RootLayout';

export default function IdentityPage() {
    return (
        <>
            <MetaTagsProvider
                title="True Continuous Monitoring | Authenticate.com"
                description="Track real-time insights into continuous criminal activities with Authenticate.com’s advanced monitoring solutions. Stay informed and ahead with our comprehensive, 24/7 crime tracking technology designed for accuracy and immediacy. Safeguard your community or organization with cutting-edge tools from Authenticate.com."
                path="products/tcm"
                ogImage="https://cdn.authenticating.com/public/website/product_pages/authenticate-tcm-open-graph-image-alt.png"
                keywords="Age Check API, Age Verification API, Background Verification API, Background Verification SDK, Criminal Background Check API, Criminal Conviction Reporting, Driver license Verification API, Education Verification API, Employment Verification API, ID Verification & Background Check Cloud, Identity Authentication, Identity Verification API, KBA API, Knowledge Based Authentication API, Knowledge Based Authentication Quiz, Knowledge Based Authentication Solutions, License Verification API, Email Verification, Evictions API, Facial Recognition API, Global Watch List Report, Multi-Factor Verifications, National Criminal Check, Passport Authentication, Professional License Verification, SMS Verification, SSN Verification, US Corporation or Business Verification, Verify US Passport Data, Verify US Photo ID Data"
            />
            <RootLayout>
                <HeroSection
                    tag="Products · True Continuous Monitoring"
                    heading="Monitor Users for Instant Risk Alerts"
                    subHeading="Monitor users in real-time for arrests, warrants, incarcerations or bookings, new sanctions or money-laundering alerts, suspension, revocations and more."
                    heroImage={TCM_demo}
                />
                <SecondaryInfoSection
                    title="Monitor users in real time & protect your business"
                    desc1="	True Continuous Monitoring (TCM) automates and streamlines user monitoring, by constantly checking and re-checking for
                new activity, allowing businesses to proactively manage risk."
                    desc2="Just upload a roster, add users manually, or program TCM to enroll every user automatically, and the system will subscribe to over 20,000 sources to alert you whenever new activity is reported."
                    bulletPoints={tcmBulletPoints}
                    sectionImage={Monitor_Users}
                />
                <BuildTrust cardInfo={tcmInfoCards} />
                <Coverage />
                <HelpInfo />
                <ProductFaq faqData={tcmProductPageFaq} />
                <Schedule />
            </RootLayout>
        </>
    );
}
